import type { HttpErrorResponse } from '@angular/common/http';

interface ErrorResponse {
  errorCode: string;
  errorMessage: string;
}

function getErrors(errorResponse: HttpErrorResponse): ErrorResponse[] {
  return errorResponse.error?.errors || [];
}

export function getErrorCodes(errorResponse: HttpErrorResponse): string[] {
  return getErrors(errorResponse).map((error) => error.errorCode);
}
