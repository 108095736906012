import { type HttpErrorResponse } from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { getErrorCodes } from '@clover/core/helpers/error';
import { presentConfirmationDialog } from '@design/overlays/confirmation-dialog/confirm';

import { SelfAssignmentLinkError, TasksSelfAssignmentService } from './tasks-self-assignment.service';

export const tasksSelfAssignmentResolver: ResolveFn<unknown> = (route) => {
  const { selfAssignmentId } = route.params;

  const injector = inject(Injector);

  return inject(TasksSelfAssignmentService)
    .getSelfAssignmentDetails(selfAssignmentId)
    .pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const errorCodes = getErrorCodes(errorResponse);

        if (errorCodes.includes(SelfAssignmentLinkError.Invalid)) {
          handleInactiveLinkError(injector);
          return of(null);
        }

        if (errorCodes.includes(SelfAssignmentLinkError.MissingCompanyConnection)) {
          handleMissingCompanyConnectionError(injector);
          return of(null);
        }

        handleInvalidLinkError(injector);
        return of(null);
      }),
    );
};

function handleInactiveLinkError(injector: Injector): void {
  const router = injector.get(Router);

  router.navigate(['/']);
  presentConfirmationDialog(injector, {
    title: 'Link inactivated',
    description:
      'The workflow link you followed has been inactivated and is no longer accessible. Please inform the assigner of this, if this was unexpected.',
    confirmActionText: 'OK',
    style: 'compact',
  });
}

function handleMissingCompanyConnectionError(injector: Injector): void {
  const router = injector.get(Router);

  router.navigate(['/']);
  presentConfirmationDialog(injector, {
    title: 'Connection required',
    description:
      'A connection to the issuing company is required. Please create a connection between your companies first.',
    confirmActionText: 'OK',
    style: 'compact',
  });
}

function handleInvalidLinkError(injector: Injector): void {
  const router = injector.get(Router);

  router.navigate(['/']);
  presentConfirmationDialog(injector, {
    title: 'Self assignment link is invalid',
    description: 'The self assignment link is invalid. Please contact your administrator.',
    confirmActionText: 'OK',
    style: 'compact',
  });
}
