import { inject, Injectable } from '@angular/core';
import { map, type Observable } from 'rxjs';

import type { TaskPerformerCompany, TaskPerformer, TaskPreview } from '@clover/conversations-v4/tasks/tasks.model';
import {
  mapTaskPerformer,
  mapTaskPerformerCompany,
  mapTaskPreview,
  type TaskPerformerCompanyResponse,
  type TaskPerformerResponse,
  type TaskPreviewResponse,
} from '@clover/conversations-v4/tasks/tasks.service';
import { HttpService } from '@clover/core/services/http.service';

import { TASKS_BASE_URL } from '../routes';

interface SelfAssignmentDetailsResponse {
  id: string;
  name: string;
  description: string;
  assignedBy: TaskPerformerResponse;
  assignedByCompany: TaskPerformerCompanyResponse;
}

export interface SelfAssignmentDetails {
  id: string;
  name: string;
  description: string;
  assigner: TaskPerformer;
  assignerCompany: TaskPerformerCompany;
}

export enum SelfAssignmentLinkError {
  Invalid = 'invalid_assignment_link',
  MissingCompanyConnection = 'missing_company_connection',
}

function mapSelfAssignmentDetails(response: SelfAssignmentDetailsResponse): SelfAssignmentDetails {
  return {
    id: response.id,
    name: response.name,
    description: response.description,
    assigner: mapTaskPerformer(response.assignedBy),
    assignerCompany: mapTaskPerformerCompany(response.assignedByCompany),
  };
}

export function createSelfAssignmentLink(selfAssignmentId: string): string {
  return `${TASKS_BASE_URL}/assign/${selfAssignmentId}`;
}

@Injectable({
  providedIn: 'root',
})
export class TasksSelfAssignmentService {
  private readonly http = inject(HttpService);

  getSelfAssignmentDetails(selfAssignmentId: string): Observable<SelfAssignmentDetails> {
    return this.http
      .getV2<SelfAssignmentDetailsResponse>(`api/tasks/assignmentLinks/${selfAssignmentId}/details`)
      .pipe(map(mapSelfAssignmentDetails));
  }

  acceptSelfAssignment(selfAssignmentId: string): Observable<TaskPreview> {
    return this.http
      .postV2<TaskPreviewResponse>(`api/tasks/assign/links/${selfAssignmentId}`, {})
      .pipe(map(mapTaskPreview));
  }
}
