/* eslint-disable import/order */
import { inject } from '@angular/core';
import { Router, type Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';

import { taskInfoResolver } from '@clover/conversations-v4/conversation/task-info.resolver';

import { tasksListGuard, TaskTypeRouteParam } from './tasks-list.guard';
import { tasksListResolver } from './tasks-list.resolver';
import { TasksPageComponent } from './tasks-page.component';
import { tasksSelfAssignmentResolver } from './tasks-self-assignment/tasks-self-assignment.resolver';

import { TasksState } from './state/tasks.state';
import { TasksListComponent } from './tasks-list/tasks-list.component';
// States has to be imported last due to a bug in @ngxs/store
// @see: https://github.com/ngxs/store/issues/1631
// This comment is here to prevent import sorter from reordering the imports

export const TASKS_BASE_URL = 'tasks';
export const tasksPageRoutes: Routes = [
  {
    path: '',
    component: TasksPageComponent,
    providers: [provideStates([TasksState])],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        component: TasksListComponent,
        resolve: {
          tasksLoaded: tasksListResolver,
        },
        canActivate: [tasksListGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        children: [
          {
            path: ':taskId',
            loadComponent: () =>
              import('./tasks-list/tasks-list-drawer/tasks-list-drawer.component').then(
                (c) => c.TasksListDrawerComponent,
              ),
            resolve: {
              task: taskInfoResolver,
            },
          },
        ],
      },
      {
        path: 'assign/:selfAssignmentId',
        loadComponent: () =>
          import('./tasks-self-assignment/tasks-self-assignment.component').then(
            ({ TasksSelfAssignmentComponent }) => TasksSelfAssignmentComponent,
          ),
        resolve: {
          selfAssignment: tasksSelfAssignmentResolver,
        },
      },

      // Redirects for backward compatibility
      {
        path: 'sent',
        loadComponent: () =>
          import('./tasks-list/tasks-list.component').then(({ TasksListComponent }) => TasksListComponent), // This will never render, but it's required in the route config
        canActivate: [
          () => {
            return inject(Router).createUrlTree(['/', TASKS_BASE_URL, 'list'], {
              queryParams: { type: TaskTypeRouteParam.Sent },
            });
          },
        ],
      },
      {
        path: 'received',
        loadComponent: () =>
          import('./tasks-list/tasks-list.component').then(({ TasksListComponent }) => TasksListComponent), // This will never render, but it's required in the route config
        canActivate: [
          () => {
            return inject(Router).createUrlTree(['/', TASKS_BASE_URL, 'list'], {
              queryParams: { type: TaskTypeRouteParam.Received },
            });
          },
        ],
      },
    ],
  },
];
